import select2 from "select2/dist/js/select2.full.min";
import "select2/dist/js/i18n/cs";

class Filtering {
  constructor() {
    this.handleDestinations();
    this.handleHotels();
    this.handleProducts();
    this.handleStars();
    this.handleReturnRoute();
    this.handleRegions();
    this.handleOnChangeInputs();
    this.handleAtractionTypes();

    this.delay = 500;
  }

  handleOnChangeInputs() {
    $(".sorting_link").on("click", (e) => {
      e.preventDefault();
      $("#sorting_input").val($(e.target).data("sorting"));
      $("#sorting_input").closest("form").submit();
    });
  }

  get_seasons() {
    var seasons = [];
    for (var val of $(
      "[type='checkbox'][name='q[product_season_id_in][]']:checked"
    )) {
      const value = $(val).val();
      seasons.push(value);
    }
    return seasons;
  }

  handleDestinations() {
    var self = this;
    $("#q_hotel_destination_id_in").select2({
      allowClear: true,
      multiple: true,
      containerCssClass: "fa-placeholder",
      ajax: {
        url: "/destinations/load_destinations",
        dataType: "json",
        delay: self.delay,
        data: function (str) {
          return {
            string: str.term,
            season_ids: self.get_seasons(),
            exotic: $("#q_hotel_destination_exotic_eq").is(":checked"),
            last_minute: $("#q_tour_last_minute_eq").is(":checked"),
            program: $("#q_product_tour_type_id_in_1").is(":checked"),
            hotel: $("#q_product_tour_type_id_in_2").is(":checked"),
            program_hotel: $("#q_product_tour_type_id_in_3").is(":checked"),
            page: str.page || 1,
          };
        },
        processResults: function (data) {
          let results = [];

          for (let item of data.destinations) {
            results.push({
              id: item.id,
              text: item.to_s || item.name,
            });
          }
          return {
            results: results,
            pagination: {
              more: data.last_page === false,
            },
          };
        },
      },
    });
  }

  handleHotels() {
    var self = this;
    $("#hotel_input").select2({
      placeholder: "Vyberte",
      allowClear: true,
      containerCssClass: "fa-placeholder",
      ajax: {
        url: "/hotels/load_hotels",
        dataType: "json",
        delay: self.delay,
        data: function (str) {
          return {
            string: str.term,
            destinations_ids: $("#q_hotel_destination_id_in").val(),
            region_ids: $("#q_hotel_region_id_in").val(),
            product_id: $("#product_input").val(),
            season_ids: self.get_seasons(),
            program: $("#q_product_tour_type_id_in_1").is(":checked"),
            exotic: $("#q_hotel_destination_exotic_eq").is(":checked"),
            last_minute: $("#q_tour_last_minute_eq").is(":checked"),
            hotel: $("#q_product_tour_type_id_in_2").is(":checked"),
            program_hotel: $("#q_product_tour_type_id_in_3").is(":checked"),
            page: str.page || 1,
          };
        },
        processResults: function (data) {
          let results = [];

          for (let item of data.hotels) {
            results.push({
              id: item.id,
              text: item.to_s || item.name,
            });
          }

          return {
            results: results,
            pagination: {
              more: data.last_page === false,
            },
          };
        },
      },
    });
  }

  handleProducts() {
    var self = this;
    $("#product_input").select2({
      placeholder: "Vyberte",
      allowClear: true,
      containerCssClass: "fa-placeholder",
      ajax: {
        url: "/products/load_products",
        dataType: "json",
        delay: self.delay,
        data: function (str) {
          return {
            string: str.term,
            destinations_ids: $("#q_hotel_destination_id_in").val(),
            season_ids: self.get_seasons(),
            region_ids: $("#q_hotel_region_id_in").val(),
            hotel_id: $("#hotel_input").val(),
            program: $("#q_product_tour_type_id_in_1").is(":checked"),
            exotic: $("#q_hotel_destination_exotic_eq").is(":checked"),
            last_minute: $("#q_tour_last_minute_eq").is(":checked"),
            hotel: $("#q_product_tour_type_id_in_2").is(":checked"),
            program_hotel: $("#q_product_tour_type_id_in_3").is(":checked"),
            page: str.page || 1,
          };
        },
        processResults: function (data) {
          let results = [];

          for (let item of data.products) {
            results.push({
              id: item.id,
              text: item.to_s || item.name,
            });
          }
          return {
            results: results,
            pagination: {
              more: data.last_page === false,
            },
          };
        },
      },
    });
  }

  handleStars() {
    $(".stars a").on("click", function (e) {
      e.preventDefault();
      $(".stars a").removeClass("active");
      $(this).prevAll("a").addClass("active");
      $(this).addClass("active");
      $("#q_hotel_stars_gteq").val($(this).attr("rel"));
      $("#q_hotel_stars_gteq").change();
    });
  }

  handleReturnRoute() {
    $("#term_routes_route_from_id_in").on("change", function () {
      if ($(this.val() != null)) {
        $("input#return_route").prop("disabled", false);
        $("input#return_route").prop("checked", true);
      } else {
        $("input#return_route").prop("disabled", true);
        $("input#return_route").prop("checked", false);
      }
    });
  }

  handleRegions() {
    if ($("#q_hotel_region_id_in").length == 0) return;

    var self = this;
    self.loadRegions();

    $("#q_hotel_destination_id_in").on("change", function () {
      self.loadRegions();
    });
  }

  loadRegions() {
    var selected = $("#q_hotel_region_id_in").val() || [];
    $.ajax({
      url: "/destinations/get_regions",
      data: {
        destinations: $("#q_hotel_destination_id_in").val(),
      },
      success: function (data) {
        try {
          $("#q_hotel_region_id_in").select2("destroy");
        } catch (err) {}
        $("#q_hotel_region_id_in").text("");
        $("#q_hotel_region_id_in").append("<option></option>");
        let destinations = {};
        for (let opt of data) {
          if (!opt || !opt.destination) continue;

          if (!(opt.destination.display_name in destinations)) {
            destinations[opt.destination.display_name] = [];
          }
          destinations[opt.destination.display_name].push(opt);
        }

        for (let dest in destinations) {
          let data = destinations[dest];
          let block = '<optgroup label="' + dest + '">';
          for (let opt of data) {
            if (selected.includes(String(opt.id))) {
              block +=
                "<option value='" +
                opt.id +
                "' selected>" +
                (opt.display_name || opt.name) +
                "</option>";
            } else {
              block +=
                "<option value='" +
                opt.id +
                "'>" +
                (opt.display_name || opt.name) +
                "</option>";
            }
          }
          $("#q_hotel_region_id_in").append(block);
        }
        $("#q_hotel_region_id_in").select2({
          allowClear: true,
          containerCssClass: "fa-placeholder hotel-region-select",
          width: "100% !important",
        });
        $(".hotel-region-select input").width("100%"); //because placeholder not showing
      },
    });
  }

  handleAtractionTypes() {
    $("#atraction_types_input").select2({
      allowClear: true,
      multiple: true,
      width: "100% !important",
      containerCssClass: "fa-placeholder atraction-type-select",
      ajax: {
        url: "/api/atraction_types",
        dataType: "json",
        delay: self.delay,
        data: function (str) {
          return {
            string: str.term,
            page: str.page || 1,
          };
        },
        processResults: function (data) {
          let results = [];

          for (let item of data.attraction_types) {
            results.push({
              id: item.id,
              text: item.name,
            });
          }
          return {
            results: results,
            pagination: {
              more: data.last_page === false,
            },
          };
        },
      },
    });
    $(".atraction-type-select input").width("100%"); //because placeholder not showing
  }
}

window.Filtering = Filtering;
